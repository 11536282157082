<template>
  <div class="d-flex justify-space-between flex-wrap mx-n3">
    <ModalConfirmation
      :state-modal="isShowedModal"
      :title="modalTitle"
      :color="modalColor"
      :label="modalLabel"
      @hideModal="closeModal"
      @apply="bulkAction"
    />

    <div class="d-flex col-12 col-lg-4">
      <v-select
        v-model="selectedAction"
        class="pr-2"
        :style="{ maxWidth: '150px' }"
        :items="actions"
        label="一括操作"
        outlined
        dense
      />
      <v-btn
        color="grey lighten-3"
        height="40px"
        :disabled="!(selectedAction && checkedList.length !== 0)"
        @click="openModal"
      >
        実行
      </v-btn>
    </div>
  </div>
</template>

<script>
import ModalConfirmation from "./ModalConfirmation.vue";

export default {
  name: "VideoNewBulkAndSearchWidget",
  components: { ModalConfirmation },
  props: {
    checkedList: Array,
  },
  data() {
    return {
      actions: [
        { text: "下書き", value: "unpublished" },
        { text: "公開", value: "published" },
        { text: "削除", value: "delete" },
      ],
      selectedAction: "",
      modalTitle: "",
      modalColor: "",
      modalLabel: "",
      isShowedModal: false,
    };
  },
  methods: {
    openModal() {
      if (
        !["unpublished", "published", "delete"].includes(this.selectedAction)
      ) {
        return;
      }
      this.modalTitle = "";
      this.modalColor = "";
      this.modalLabel = "";
      if (this.selectedAction === "unpublished") {
        this.modalTitle = `${this.checkedList.length}件の写真を下書きにしてもよろしいですか？`;
        this.modalColor = "primary";
        this.modalLabel = "下書きにする";
      } else if (this.selectedAction === "published") {
        this.modalTitle = `${this.checkedList.length}件の写真を公開してもよろしいですか？`;
        this.modalColor = "primary";
        this.modalLabel = "公開する";
      } else if (this.selectedAction === "delete") {
        this.modalTitle = `${this.checkedList.length}件の写真を削除してもよろしいですか？`;
        this.modalColor = "red";
        this.modalLabel = "削除";
      }
      this.isShowedModal = true;
    },
    closeModal() {
      this.isShowedModal = false;
    },
    async bulkAction() {
      this.isShowedModal = false;

      if (
        !["unpublished", "published", "delete"].includes(this.selectedAction)
      ) {
        return;
      }

      const bulkList = {
        type:
          this.selectedAction === 'unpublished' ? 'draft' : this.selectedAction,
        ids: this.checkedList.map((video) => video.id),
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/admin/videos/bulk`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.getters['auth/token']}`,
        },
        body: JSON.stringify(bulkList),
      }).catch((e) => {
        throw e
      })

      this.$emit("reload-videos")
    },
  },
};
</script>
